import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";
import { readingTime as readingTimeHelper } from "@tryghost/helpers";
import { isMobile } from "react-device-detect";
import {
    IoPersonOutline,
    IoCalendarOutline,
    IoTimeOutline,
} from "react-icons/io5";
import { Script } from "gatsby";
import init from "../utils/ghost_codeinjection.js";

const Post = ({ data, location, pageContext }) => {
    const post = data.ghostPost;
    const image = getImage(post.childFile?.childImageSharp);
    const readingTime = readingTimeHelper(post);
    const isSSR = typeof window === "undefined";
    // const [ready, setReady] = useState(false);
    //post.html = post.html.replaceAll("ghost.truefairnews.com", "truefair.news");

    useEffect(() => {
        // console.log(data.ghostSettings.codeinjection_head);
        if (!isSSR) {
            // console.log(location, pageContext);
            if (!post.codeinjection_foot) {
                init();
            }

            window.adsbygoogle = window.adsbygoogle || [];
            window.adsbygoogle.push({});
        }
    }, []);
    return (
        <>
            <MetaData data={data} location={location} type="article" />
            <Helmet></Helmet>
            <Layout>
                <div className="container">
                    <article className="content">
                        {post.feature_image ? (
                            <figure
                                className="post-feature-image"
                                style={{
                                    padding: isMobile ? "0" : "2rem 2rem 0 ",
                                }}
                            >
                                <GatsbyImage
                                    alt={post.title}
                                    image={image}
                                    style={{ width: "100%" }}
                                />
                            </figure>
                        ) : null}
                        <section className="post-full-content">
                            <div className="post-full-meta">
                                <h1 className="content-title">{post.title}</h1>
                                <div className="post_meta">
                                    <IoPersonOutline id="post-icons" />
                                    <span className="meta_item">
                                        {post.primary_author?.name}
                                    </span>
                                    <IoCalendarOutline id="post-icons" />
                                    <span className="meta_item">
                                        {post.published_at_pretty}
                                    </span>
                                    <IoTimeOutline id="post-icons" />
                                    <span className="meta_item">
                                        {readingTime}
                                    </span>
                                </div>
                            </div>

                            {/* The main post content */}
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                            <ins
                                className="adsbygoogle"
                                style={{
                                    display: "block",
                                    textAlign: "center",
                                }}
                                data-ad-layout="in-article"
                                data-ad-format="fluid"
                                data-ad-client="ca-pub-7753864306134321"
                                data-ad-slot="8596907256"
                            ></ins>
                        </section>
                    </article>
                </div>
            </Layout>
            {/* <Script
                id="codeinjection"
                src="https://ghost.truefairnews.com/assets/js/ghost-code-injection.js"
                onLoad={() => setReady(true)}
            ></Script> */}
            {!!post.codeinjection_foot && (
                <Script id={pageContext.slug + "-script"}>
                    {post.codeinjection_foot
                        .replaceAll("<script>", "")
                        .replaceAll("</script>", "")}
                </Script>
            )}
        </>
    );
};

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Post;

export const postQuery = graphql`
    query ($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
        ghostSettings {
            codeinjection_head
        }
    }
`;
