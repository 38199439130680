import React from "react";
import { Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper";
import { readingTime as readingTimeHelper } from "@tryghost/helpers";
import { isDesktop, isMobile } from "react-device-detect";
import {
    IoArrowBack,
    IoArrowForward,
    IoPersonOutline,
    IoCalendarOutline,
    IoTimeOutline,
} from "react-icons/io5";

const Featured = ({ featured }) => {
    if (typeof window === "undefined") {
        return <div className="featured swiper"></div>;
    }
    return (
        <div>
            <Swiper
                className="featured"
                modules={[Pagination, Autoplay, Navigation]}
                slidesPerView={"auto"}
                centeredSlides={true}
                spaceBetween={0}
                pagination={{
                    clickable: true,
                    el: ".featured_bullets",
                    type: "bullets",
                }}
                lazy={true}
                loop={true}
                autoplay={{ delay: 5000 }}
                navigation={{
                    nextEl: "#featured_forward",
                    prevEl: "#featured_back",
                }}
            >
                {featured.map(({ node: post }, i) => {
                    let readingTime = readingTimeHelper(post);
                    const image = getImage(post.childFile?.childImageSharp);

                    return (
                        <SwiperSlide className="featured_item" key={post.slug}>
                            <figure
                                className="featured_figure"
                                style={{
                                    height: "100%",
                                }}
                            >
                                {image ? (
                                    <GatsbyImage
                                        className="featured_img"
                                        image={image}
                                        alt={post.title}
                                    />
                                ) : (
                                    <img
                                        className="featured_img"
                                        src="/images/placeholder.png"
                                        alt={post.title}
                                    />
                                )}
                                <figcaption>
                                    {post.primary_tag && (
                                        <div
                                            className="tag_cloud"
                                            style={{ margin: "0.5rem auto" }}
                                        >
                                            <Link
                                                to={
                                                    "/tag/" +
                                                    post.primary_tag.slug
                                                }
                                            >
                                                <div className="tag">
                                                    {post.primary_tag.name}
                                                </div>
                                            </Link>
                                        </div>
                                    )}

                                    <Link to={"/" + post.slug}>
                                        <h4 className="trim_title">
                                            {post.title}
                                        </h4>
                                    </Link>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: isMobile
                                                ? "column"
                                                : "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <div>
                                            <IoPersonOutline id="post-icons" />
                                            <span className="meta_item">
                                                {post.primary_author?.name}
                                            </span>
                                        </div>
                                        <div>
                                            <IoCalendarOutline id="post-icons" />
                                            <span className="meta_item">
                                                {post.published_at_pretty}
                                            </span>
                                        </div>
                                        {isDesktop && (
                                            <div>
                                                <IoTimeOutline id="post-icons" />
                                                <span className="meta_item">
                                                    {readingTime}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </figcaption>
                            </figure>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <div
                className="featured_pagination"
                style={{
                    width: "100%",
                    height: "2.5rem",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <IoArrowBack id="featured_back" />
                <div
                    className="featured_bullets"
                    style={{
                        width: "auto",
                        alignItems: "center",
                        display: "flex",
                    }}
                ></div>
                <IoArrowForward id="featured_forward" />
            </div>
        </div>
    );
};
export default Featured;
