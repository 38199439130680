//window.GHOST_FINDER_API_KEY = "1ac12f25e6d091084f45c73da8";

let candidate2016 = ["Trump", "Clinton", "Sanders"];
let candidate2020 = ["Trump", "Biden", "Sanders"];
let candidate2012 = ["Obama", "Romney", "Uncommitted"];
let candidate2008 = ["Obama", "McCain", "Other"];
class loadDataOld {
    constructor(year, state, type) {
        this.colors = {
            "Bernie Sanders": "#303F9F",
            Sanders: "#303F9F",
            "Hillary Clinton": "#395fc4",
            Biden: "#395fc4",
            Weld: "#29B6F6",
            Warren: "#7986CB",
            "Mitt Romney": "#FC1506",
            "Barack Obama": "#395fc4",
            McCain: "#FC1506",
            "John McCain": "#FC1506",
            Bloomberg: "#C5CAE9",
            Gabbard: "#283593",
            Trump: "#FC1506",
            "Donald J. Trump": "#FC1506",
            "Marco Rubio": "#EC7063",
            "Ted Cruz": "#F55C3B",
            "John Kasich": "#F7866D",
            "State Votes": "#2d3436",
            "Total Votes": "#00b894",
            "Martin O'Malley": "#D6EAF8",
            "Jeb Bush": "#F7A9A4",
            "Jill Stein": "#d84193",
            Others: "gray",
            Other: "gray",
        };
        this.year = year;
        this.state = state;
        this.type = type;
        console.log("year1:", year);
        console.log("type1:", type);
        console.log("state1:", state);
        this.candidatesList = [];
        if (year === "2016") this.candidatesList = candidate2016;
        else if (year === "2020") this.candidatesList = candidate2020;
        else if (year === "2012") this.candidatesList = candidate2012;
        else if (year === "2008") this.candidatesList = candidate2008;
        this.altType = this.type === "general" ? "primary" : "general";
        //this.baseURL = 'https://tfn-beta-api.herokuapp.com/us_elections'
        this.baseURL = "https://api.truefairnews.com/v1/us_elections";
        this.votesURL = `${this.baseURL}/candidates/results?states=${this.state}&electiontype=${this.type}&electionYear=${this.year}`;
        this.countiesURL = `${this.baseURL}/candidates/won_counties?states=${this.state}&electiontype=${this.type}&electionYear=${this.year}`;
        this.countiesPrimaryURL = `${this.baseURL}/candidates/won_counties?states=${this.state}&electiontype=${this.altType}&electionYear=${this.year}`;

        this.statePercentURL = `${this.baseURL}/candidates/states_result?names=${this.candidatesList[0]}%2C${this.candidatesList[1]}%2C${this.candidatesList[2]}&states=${this.state}&electiontype=${this.type}&electionYear=${this.year}&h2h=false`;
        this.statePercentAltURL = `${this.baseURL}/candidates/states_result?names=${this.candidatesList[0]}%2C${this.candidatesList[1]}%2C${this.candidatesList[2]}&states=${this.state}&electiontype=${this.altType}&electionYear=${this.year}&h2h=false`;

        this.stateContributionURL = `${this.baseURL}/candidates/states_contribution?names=${this.candidatesList[0]}%2C${this.candidatesList[1]}&states=${this.state}&electiontype=${this.type}&electionYear=${this.year}`;
        this.stateContributionAltURL = `${this.baseURL}/candidates/states_contribution?names=${this.candidatesList[0]}%2C${this.candidatesList[1]}&states=${this.state}&electiontype=${this.altType}&electionYear=${this.year}`;

        this.countiesResultURL = `${this.baseURL}/topCounties?states=${this.state}&electiontype=${this.type}&topN=15&nationally=false&electionYear=${this.year}`;
        this.countiesPrimaryResultURL = `${this.baseURL}/topCounties?states=${this.state}&electiontype=${this.altType}&topN=15&nationally=false&electionYear=${this.year}`;

        this.topFifteenCountiesPrimaryURL = `${this.baseURL}/topCandidatesCounties?names=${this.candidatesList[0]}%2C${this.candidatesList[1]}&states=${this.state}&electiontype=${this.altType}&topN=15&nationally=false&electionYear=${this.year}`;
        this.topFifteenCountiesGeneralURL = `${this.baseURL}/topCandidatesCounties?names=${this.candidatesList[0]}%2C${this.candidatesList[1]}&states=${this.state}&electiontype=${this.type}&topN=15&nationally=false&electionYear=${this.year}`;

        this.topTenCountiesByCandidateURL = `${this.baseURL}/topCandidatesCounties?names=${this.candidatesList[0]}%2C${this.candidatesList[1]}%2C${this.candidatesList[2]}&states=${this.state}&electiontype=${this.type}&topN=10&nationally=false&electionYear=${this.year}`;
    }

    async headToheadTotals(candidates, container) {
        let url = `${this.baseURL}/candidates/results?names=${candidates[0]}%2C${candidates[1]}&states=${this.state}&electiontype=${this.type}&electionYear=${this.year}&h2h=false`;
        let data = await this.fetchURL(url, this.statePercentMap);
        const displayData = new google.visualization.arrayToDataTable(data);
        let options = this.getSliceColor(displayData);
        const totalsPie = new google.visualization.PieChart(container);
        totalsPie.draw(displayData, options);
    }

    async stateContibution() {
        // Clinton's Popular Vote Victory Contributed by California at the 2016 General Elections
        //general
        const data = await this.fetchURL(
            this.stateContributionURL,
            this.mapStateContribution
        );
        const displayData = new google.visualization.arrayToDataTable(data);
        const options = this.getSliceColor(displayData);
        const stateContribPie = new google.visualization.PieChart(
            document.getElementById(`state_contribution_${this.type}`)
        );
        stateContribPie.draw(displayData, options);

        //primary
        const altData = await this.fetchURL(
            this.stateContributionAltURL,
            this.mapStateContribution
        );
        const altDisplayData = new google.visualization.arrayToDataTable(
            altData
        );
        const altOptions = this.getSliceColor(altDisplayData);
        const stateContribAltPie = new google.visualization.PieChart(
            document.getElementById(`state_contribution_${this.altType}`)
        );
        stateContribAltPie.draw(altDisplayData, altOptions);
    }

    async statePercent() {
        //2016 CA Presidential Primary vs Presidential General Elections w.r.t % Votes and Total Votes Won
        //primary state percent per candidate
        const statePercentData = await this.fetchURL(
            this.statePercentURL,
            this.statePercentMap
        );
        const statePercentDisplayData =
            new google.visualization.arrayToDataTable(statePercentData);
        const options = this.getSliceColor(statePercentDisplayData);
        const statePercentChart = new google.visualization.PieChart(
            document.getElementById(`state_percent_${this.type}`)
        );
        statePercentChart.draw(statePercentDisplayData, options);

        const statePercentAltData = await this.fetchURL(
            this.statePercentAltURL,
            this.statePercentMap
        );
        const statePercentAltDisplayData =
            new google.visualization.arrayToDataTable(statePercentAltData);
        const altOptions = this.getSliceColor(statePercentAltDisplayData);
        const statePercentAltChart = new google.visualization.PieChart(
            document.getElementById(`state_percent_${this.altType}`)
        );
        statePercentAltChart.draw(statePercentAltDisplayData, altOptions);
    }

    async topFifteenCandidatesCounties() {
        if (this.type === "general") {
            console.log("general here");
            let header = [
                { name: "County", type: "string" },
                { name: "Votes", type: "number" },
            ];
            //if(this.year !=="2012"){
            if (!["2012", "2008"].includes(this.year)) {
                let primaryData = await this.fetchURL(
                    this.topFifteenCountiesPrimaryURL,
                    this.mapTopfifteenCounties
                );
                this.showTable(
                    primaryData[this.candidatesList[1].toLowerCase()],
                    document.querySelector(
                        `#${this.candidatesList[1].toLowerCase()}_primary_top15`
                    ),
                    header
                );
                this.showTable(
                    primaryData[this.candidatesList[0].toLowerCase()],
                    document.querySelector(
                        `#${this.candidatesList[0].toLowerCase()}_primary_top15`
                    ),
                    header
                );
            }

            let generalData = await this.fetchURL(
                this.topFifteenCountiesGeneralURL,
                this.mapTopfifteenCounties
            );
            console.log("general Data:", generalData);
            console.log(
                `#${this.candidatesList[1].toLowerCase()}_general_top15`
            );
            this.showTable(
                generalData[this.candidatesList[1].toLowerCase()],
                document.querySelector(
                    `#${this.candidatesList[1].toLowerCase()}_general_top15`
                ),
                header
            );
            this.showTable(
                generalData[this.candidatesList[0].toLowerCase()],
                document.querySelector(
                    `#${this.candidatesList[0].toLowerCase()}_general_top15`
                ),
                header
            );
        } else {
            console.log("primary here");
            console.log(
                `#top_10_counties_${this.candidatesList[1].toLowerCase()}`
            );
            console.log(
                `#top_10_counties_${this.candidatesList[0].toLowerCase()}`
            );
            console.log(
                `#top_10_counties_${this.candidatesList[2].toLowerCase()}`
            );
            //primary top 10 counties stats by candidates
            let header = [
                { name: "County", type: "string" },
                { name: "Votes", type: "number" },
            ];
            let data = await this.fetchURL(
                this.topTenCountiesByCandidateURL,
                this.mapTopfifteenCounties
            );
            console.log(data);
            this.showTable(
                data[this.candidatesList[2].toLowerCase()],
                document.querySelector(
                    `#top_10_counties_${this.candidatesList[2].toLowerCase()}`
                ),
                header
            );
            this.showTable(
                data[this.candidatesList[1].toLowerCase()],
                document.querySelector(
                    `#top_10_counties_${this.candidatesList[1].toLowerCase()}`
                ),
                header
            );
            this.showTable(
                data[this.candidatesList[0].toLowerCase()],
                document.querySelector(
                    `#top_10_counties_${this.candidatesList[0].toLowerCase()}`
                ),
                header
            );
        }
    }

    async topFifteenVotes() {
        //primary top15 table
        //if(this.year !== "2012"){
        if (!["2012", "2008"].includes(this.year)) {
            let countiesPrimaryData = await this.fetchURL(
                this.countiesPrimaryResultURL,
                this.mapTopFifteen
            );
            let primaryTableData = new google.visualization.DataTable();
            primaryTableData.addColumn("string", "County");
            primaryTableData.addColumn("number", "Votes");
            primaryTableData.addRows(countiesPrimaryData);
            let primaryTable = new google.visualization.Table(
                document.getElementById("counties_top15_primary_data")
            );
            primaryTable.draw(primaryTableData, {
                showRowNumber: true,
                width: "100%",
                height: "100%",
            });
        }

        //general top15 table
        let countiesGeneralData = await this.fetchURL(
            this.countiesResultURL,
            this.mapTopFifteen
        );
        console.log("topFifteenVotes");
        let generalTableData = new google.visualization.DataTable();
        generalTableData.addColumn("string", "County");
        generalTableData.addColumn("number", "Votes");
        generalTableData.addRows(countiesGeneralData);
        let generalTable = new google.visualization.Table(
            document.getElementById("counties_top15_general_data")
        );
        generalTable.draw(generalTableData, {
            showRowNumber: true,
            width: "100%",
            height: "100%",
        });
    }

    async topTenCountyVoters() {
        //primary top15 table
        let countiesPrimaryData = await this.fetchURL(
            this.countiesResultURL,
            this.mapTopTen
        );
        let primaryTableData = new google.visualization.DataTable();
        primaryTableData.addColumn("string", "County");
        primaryTableData.addColumn("number", "Votes");
        primaryTableData.addRows(countiesPrimaryData);
        let primaryTable = new google.visualization.Table(
            document.getElementById("counties_top_10_votes")
        );
        primaryTable.draw(primaryTableData, {
            showRowNumber: true,
            width: "100%",
            height: "100%",
        });
    }

    async loadPrimaryCounty() {
        //if(this.year != "2012"){
        if (!["2012", "2008"].includes(this.year)) {
            let primaryData = await this.fetchURL(this.countiesPrimaryURL);
            let mappedData = this.mapCounties(primaryData);
            let tableData = new google.visualization.DataTable();
            tableData.addColumn("string", "Name");
            tableData.addColumn("string", "Counties");
            tableData.addRows(mappedData);
            let table = new google.visualization.Table(
                document.getElementById("counties_primary_data")
            );
            table.draw(tableData, {
                showRowNumber: true,
                width: "100%",
                height: "100%",
            });
        }
    }

    async loadCountyComparison() {
        //general data
        let generalData = await this.fetchURL(this.countiesURL);
        console.log(generalData);
        let mappedData1 = this.mapCounties(generalData);
        let tableData1 = new google.visualization.DataTable();
        tableData1.addColumn("string", "Name");
        tableData1.addColumn("string", "Counties");
        tableData1.addRows(mappedData1);
        let table1 = new google.visualization.Table(
            document.getElementById("counties_comparison_general")
        );
        table1.draw(tableData1, {
            showRowNumber: true,
            width: "100%",
            height: "100%",
        });

        //primary data
        let primaryData = await this.fetchURL(this.countiesPrimaryURL);
        let mappedData2 = this.mapCounties(primaryData);
        let tableData2 = new google.visualization.DataTable();
        tableData2.addColumn("string", "Name");
        tableData2.addColumn("string", "Counties");
        tableData2.addRows(mappedData2);
        let table2 = new google.visualization.Table(
            document.getElementById("counties_comparison_primary")
        );
        table2.draw(tableData2, {
            showRowNumber: true,
            width: "100%",
            height: "100%",
        });
    }

    async loadVotesChart() {
        const data = await this.fetchURL(this.votesURL).then(); //get the data from server and map it for google chart
        const mappedData = this.mapVotes(data);
        const displayData = new google.visualization.arrayToDataTable(
            mappedData
        ); //convert the data to google chart data type;
        const options = this.getSliceColor(displayData);
        const chart = new google.visualization.PieChart(
            document.getElementById("votes_data")
        ); //supply chart location
        chart.draw(displayData, options);
    }

    async loadCountiesTable() {
        let data = await this.fetchURL(this.countiesURL);
        let mappedData = this.mapCounties(data);
        let tableData = new google.visualization.DataTable();
        tableData.addColumn("string", "Name");
        tableData.addColumn("string", "Counties");
        tableData.addRows(mappedData);
        let table = new google.visualization.Table(
            document.getElementById("counties_data")
        );
        table.draw(tableData, {
            showRowNumber: true,
            width: "100%",
            height: "100%",
        });
    }

    mapStateContribution({ data }) {
        let mappedData = [];
        if (
            data[this.state][1]["State/Votes"] >
            data[this.state][0]["State/Votes"]
        ) {
            mappedData.push([
                "State Votes",
                data[this.state][0]["State/Votes"],
            ]);
            mappedData.push([
                "Total Votes",
                data[this.state][0].candidatePopularVotesNationally,
            ]);
        } else {
            mappedData.push([
                "State Votes",
                data[this.state][0]["State/Votes"],
            ]);
            mappedData.push([
                "Total Votes",
                data[this.state][0].candidatePopularVotesNationally,
            ]);
        }
        return [["State Contribution", "Total Votes"], ...mappedData];
    }

    mapTopfifteenCounties({ data }) {
        let mappedData = data[this.state].map((e) => {
            let candidateName = Object.keys(e)[0];
            let data = e[candidateName].map((data) => [
                data.County,
                data["County/Votes"],
            ]);
            let object = {};
            let identifier;
            this.candidatesList.map((candidate) => {
                if (candidateName.includes(candidate))
                    identifier = candidate.toLowerCase();
            });

            // if (candidateName.includes('Trump')) identifier = 'trump';
            // if (candidateName.includes('Sanders')) identifier = 'sanders';
            object[identifier] = data;
            return object;
        });
        let obj = {};
        mappedData.forEach(
            (e) => (obj[Object.keys(e)[0]] = e[Object.keys(e)[0]])
        );
        return obj;
    }

    mapTopFifteen({ data }) {
        return data[this.state].map((e) => [e.County, e.totalVotes]);
    }
    mapTopTen({ data }) {
        return data[this.state]
            .slice(0, 10)
            .map((e) => [e.County, e.totalVotes]);
    }

    mapVotes({ data }) {
        //Maps votes data so that google charts can use the data
        let mappedData = data[this.state].map((e) => {
            return [e.Name, e["State/Votes"]];
        });

        let array = [["People", "Votes"], ...mappedData];
        return array;
    }

    mapCounties({ data }) {
        let mappedData = data[this.state].metadata.candidates.map((e) => {
            return [e.name, `${e.totalCountiesWon} Counties`];
        });
        let sortedData = mappedData.sort(
            (a, b) => b.totalCountiesWon - a.totalCountiesWon
        );
        return sortedData;
    }

    statePercentMap({ data }) {
        let mappedData = data[this.state].map((e) => [
            e.Name,
            e["State/Votes"],
        ]);
        let newData = [["Candidate", "Percent"], ...mappedData];
        return newData;
    }

    async fetchURL(url, mapFunc) {
        if (mapFunc) {
            let data = await fetch(url).then((r) => r.json());
            return mapFunc.bind(this, data)();
        }
        return await fetch(url).then((r) => r.json());
    }

    showTable(data, nodeLocation, columns) {
        let tableData = new google.visualization.DataTable();
        columns.forEach((e) => tableData.addColumn(e.type, e.name));
        tableData.addRows(data);
        let table = new google.visualization.Table(nodeLocation);
        table.draw(tableData, {
            showRowNumber: true,
            width: "100%",
            height: "100%",
        });
    }

    getSliceColor(data) {
        let slices = [];
        for (let i = 0; i < data.getNumberOfRows(); i++) {
            slices.push({
                color:
                    this.colors[data.getValue(i, 0)] || this.getRandomColor(),
            });
        }
        const options = {
            is3D: true,
            legend: { position: "bottom" },
            height: "450",
            slices,
            backgroundColor: "#f4f4f4",
        };
        return options;
    }

    getRandomColor() {
        let fallBackColors = ["black", "yellow", "cyan"];
        return fallBackColors[Math.floor(Math.random() * 3)];
    }
}
export default function init() {
    if (window.location.pathname === "/" || window.location.pathname === "")
        return false; //guard
    const toUpperCase = (s) => s.replace(/./, (m) => m.toUpperCase());
    let [year, test, type, state] = window.location.pathname
        .replace(/\//g, "")
        .split("-");
    state = state?.toLowerCase();
    state = toUpperCase(state);
    console.log("year:", year);
    console.log("type:", type);
    console.log("state:", state);
    let dataDisplay = new loadDataOld(year, state, type);
    google.charts.load("current", { packages: ["corechart", "table"] });
    let candidatesList = [];
    if (year === "2016") candidatesList = candidate2016;
    if (year === "2020") candidatesList = candidate2020;
    if (year === "2012") candidatesList = candidate2012;
    if (year === "2008") candidatesList = candidate2008;
    async function main() {
        if (type === "general") {
            console.log("General DAta here");
            console.log(
                `#totals_${candidatesList[1].toLowerCase()}_${candidatesList[0].toLowerCase()}`
            );
            await dataDisplay.loadVotesChart().catch(console.log);
            await dataDisplay.loadCountiesTable().catch(console.log);
            await dataDisplay.loadCountyComparison().catch(console.log);
            await dataDisplay.loadPrimaryCounty().catch(console.log);
            await dataDisplay.statePercent().catch(console.log);
            await dataDisplay.topFifteenVotes().catch(console.log);
            await dataDisplay.topFifteenCandidatesCounties().catch(console.log);
            await dataDisplay.stateContibution().catch(console.log);
        }
        if (type === "primary") {
            console.log("Primary DAta here");
            await dataDisplay.loadVotesChart().catch(console.log);
            await dataDisplay.loadCountiesTable().catch(console.log);
            await dataDisplay.topTenCountyVoters().catch(console.log);
            await dataDisplay.topFifteenCandidatesCounties().catch(console.log);
            console.log(
                `#totals_${candidatesList[1].toLowerCase()}_${candidatesList[0].toLowerCase()}`
            );
            await dataDisplay.headToheadTotals(
                [candidatesList[2], candidatesList[1]],
                document.querySelector(
                    `#totals_${candidatesList[2].toLowerCase()}_${candidatesList[1].toLowerCase()}`
                )
            );
            await dataDisplay.headToheadTotals(
                [candidatesList[1], candidatesList[0]],
                document.querySelector(
                    `#totals_${candidatesList[1].toLowerCase()}_${candidatesList[0].toLowerCase()}`
                )
            );
            await dataDisplay.headToheadTotals(
                [candidatesList[0], candidatesList[2]],
                document.querySelector(
                    `#totals_${candidatesList[0].toLowerCase()}_${candidatesList[2].toLowerCase()}`
                )
            );
        }
    }
    // let synced = false;
    // let ping;
    // const event = new Event("CODEINJ_LOADED");
    // if (!synced) {
    //     ping = setInterval(() => {
    //         window.dispatchEvent(event);
    //         console.log("ping");
    //     }, 100);
    // }

    // window.addEventListener("GATSBY_LOADED", () => {
    //     console.log("pong");
    //     synced = true;
    //     clearInterval(ping);
    //     ping = null;
    //     google.charts.setOnLoadCallback(main);
    // });

    google.charts.setOnLoadCallback(main);
}

//document.getElementsByClassName("brand-logo-light")[0].srcset = "";
