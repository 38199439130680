import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import {
    Layout,
    PostCard,
    Pagination,
    SidebarPostCard,
    Ad,
} from "../components/common";
import { MetaData } from "../components/common/meta";
import Featured from "../components/common/Featured";
// import SidebarPostCard from "../components/common/SidebarPostCard";

/**
 * Main index page (home page)
 *
 * Loads all posts from Ghost and uses pagination to navigate through them.
 * The number of posts that should appear per page can be setup
 * in /utils/siteConfig.js under `postsPerPage`.
 *
 */
const Index = ({ data, location, pageContext }) => {
    const isSSR = typeof window === "undefined";
    const posts = data.allPosts.edges;
    const posts2020 = data.latest.edges;
    const tags = data.allGhostTag.edges;
    const featured = data.featured.edges;
    const [postsWithAds, setPostsWithAds] = useState([]);
    useEffect(() => {
        let insertTokenEveryN = (arr, token, n, fromEnd) => {
            // Clone the received array, so we don't mutate the
            // original one. You can ignore this if you don't mind.
            let a = arr.slice(0);
            // Insert the <token> every <n> elements.
            let idx = fromEnd ? a.length - n : n;
            while (fromEnd ? idx >= 1 : idx <= a.length) {
                a.splice(idx, 0, token);
                idx = fromEnd ? idx - n : idx + n + 1;
            }
            return a;
        };
        const ad = (
            <ins
                className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-format="fluid"
                data-ad-layout-key="-5z+e4+1g-5x+b9"
                data-ad-client="ca-pub-7753864306134321"
                data-ad-slot="3661109499"
            ></ins>
        );
        setPostsWithAds(insertTokenEveryN(posts, ad, 3, true));
        if (!isSSR) {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
    }, []);

    return (
        <>
            <MetaData location={location} />
            <Layout isHome={true}>
                <Featured featured={featured} />
                <Container className="main-container">
                    <Row className="main-container-row">
                        <Col md={8}>
                            <section className="post-feed">
                                {postsWithAds.length > 0
                                    ? postsWithAds.map((el, i) => {
                                          if (el.node) {
                                              return (
                                                  <PostCard
                                                      key={el.node.id}
                                                      post={el.node}
                                                  />
                                              );
                                          } else {
                                              return <Ad key={i}>{el}</Ad>;
                                          }
                                      })
                                    : posts.map(({ node }) => (
                                          <PostCard key={node.id} post={node} />
                                      ))}
                            </section>
                            <Pagination pageContext={pageContext} />
                        </Col>
                        <Col md={4}>
                            <div className="post_list post_list_style_1">
                                <div className="alith_heading">
                                    <h4 className="alith_heading_patern_2 no-pattern">
                                        2020 Election Stories
                                    </h4>
                                </div>
                                <section className="posts the-latest-posts">
                                    <ins
                                        className="adsbygoogle"
                                        style={{ display: "block" }}
                                        data-ad-format="fluid"
                                        data-ad-layout-key="-hf-3+4s-ew+b6"
                                        data-ad-client="ca-pub-7753864306134321"
                                        data-ad-slot="1774663828"
                                    ></ins>
                                    {posts2020.map(({ node }) => {
                                        return (
                                            <SidebarPostCard
                                                key={node.id}
                                                post={node}
                                            />
                                        );
                                    })}
                                </section>

                                <div className="alith_heading">
                                    <h4 className="alith_heading_patern_2 no-pattern">
                                        Tags
                                    </h4>
                                </div>
                                <section>
                                    <div className="tag_cloud_sidebar">
                                        {tags.map((tag) => {
                                            return (
                                                <div
                                                    key={tag.node.slug}
                                                    className="tag_sidebar"
                                                >
                                                    <Link
                                                        to={
                                                            "/tag/" +
                                                            tag.node.slug
                                                        }
                                                    >
                                                        {tag.node.name}
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </section>
                                {/* SIDEBAR AD */}
                                <ins
                                    className="adsbygoogle"
                                    style={{
                                        display: "block",
                                        marginTop: "1rem",
                                    }}
                                    data-ad-client="ca-pub-7753864306134321"
                                    data-ad-slot="6064526532"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"
                                ></ins>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Layout>
        </>
    );
};

Index.propTypes = {
    data: PropTypes.shape({
        allPosts: PropTypes.object.isRequired,
        latest: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
};

export default Index;

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination

export const pageQuery = graphql`
    query GhostPostQuery($limit: Int!, $skip: Int!) {
        allPosts: allGhostPost(
            sort: { order: DESC, fields: [published_at] }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
        latest: allGhostPost(
            filter: { tags: { elemMatch: { name: { eq: "2020" } } } }
            limit: 10
        ) {
            edges {
                node {
                    title
                    slug
                    reading_time
                    published_at
                    published_at_pretty: published_at(
                        formatString: "DD MMMM, YYYY"
                    )
                    primary_author {
                        name
                    }
                    feature_image
                    id
                    url
                    tags {
                        name
                        slug
                    }
                    childFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 300
                                placeholder: DOMINANT_COLOR
                            )
                        }
                    }
                }
            }
        }
        featured: allGhostPost(filter: { featured: { eq: true } }) {
            edges {
                node {
                    url
                    title
                    published_at
                    published_at_pretty: published_at(
                        formatString: "DD MMMM, YYYY"
                    )
                    primary_author {
                        name
                    }
                    reading_time
                    slug
                    feature_image
                    primary_tag {
                        name
                        slug
                    }
                    childFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 1000
                                placeholder: DOMINANT_COLOR
                            )
                        }
                    }
                }
            }
        }
        allGhostTag(filter: { name: { ne: "_sidebar-post" } }) {
            edges {
                node {
                    id
                    slug
                    name
                }
            }
        }
    }
`;
