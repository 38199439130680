import React from "react";
import { graphql } from "gatsby";

import { Layout, SearchPostCard } from "../components/common";
import queryString from "query-string";

const Search = ({ data, location, pageContext }) => {
    if (typeof window === "undefined") {
        return <div></div>;
    }
    const windowLocation = location ? location : window.location;
    let posts = [];
    if (windowLocation) {
        const parsed = queryString.parse(window.location.search);
        if (data) {
            posts = data?.allGhostPost?.edges?.filter((post) =>
                post?.node?.title
                    .toLowerCase()
                    .includes(parsed?.search?.toLowerCase())
            );
        }
    }

    return (
        <>
            {/* <MetaData data={data} location={location} type="series" /> */}
            <Layout>
                <div className="container">
                    <article className="content">
                        <article className="post-full-content">
                            <div className="post-full-meta">
                                <h1 className="content-title">
                                    Search Results
                                </h1>
                            </div>
                            <section className="post-feed">
                                {posts.map(({ node }) => (
                                    // The tag below includes the markup for each post - components/common/PostCard.js
                                    <SearchPostCard key={node.id} post={node} />
                                ))}
                            </section>
                        </article>
                    </article>
                </div>
            </Layout>
        </>
    );
};

export default Search;

export const pageQuery = graphql`
    {
        allGhostPost {
            edges {
                node {
                    title
                    id
                    slug
                    feature_image
                    excerpt
                    primary_author {
                        name
                    }
                    published_at_pretty: published_at(
                        formatString: "DD MMMM, YYYY"
                    )
                    reading_time
                    childFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 1000
                                placeholder: DOMINANT_COLOR
                            )
                        }
                    }
                }
            }
        }
    }
`;
