import React, { useState, useEffect, useRef } from "react";
import { graphql, useStaticQuery, Link, navigate } from "gatsby";
import { Modal, Form } from "react-bootstrap";

export default function SearchPopup({ showSearch, setShowSearch }) {
    const [searchedPosts, setSearchedPosts] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const data = useStaticQuery(graphql`
        {
            allGhostPost {
                edges {
                    node {
                        title
                        id
                        slug
                    }
                }
            }
        }
    `);
    const searchBar = useRef(null);
    useEffect(() => {
        if (searchBar.current) {
            searchBar.current.focus();
        }
    });
    return (
        <Modal
            show={showSearch}
            onHide={() => {
                setShowSearch(false);
                setSearchedPosts([]);
                setSearchTerm("");
            }}
        >
            <Form>
                <Form.Control
                    placeholder="Search..."
                    ref={searchBar}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            setShowSearch(false);
                            navigate("/search/?search=" + e.target.value);
                        }
                        setSearchTerm(e.target.value);
                        if (data) {
                            setSearchedPosts(
                                data?.allGhostPost?.edges
                                    ?.filter((post) =>
                                        post?.node?.title
                                            .toLowerCase()
                                            .includes(
                                                e.target.value.toLowerCase()
                                            )
                                    )
                                    .slice(0, 10)
                            );
                        }
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        console.log("bb");
                    }}
                />
            </Form>
            <div className="quickSearchResults">
                {searchTerm &&
                    (searchedPosts.length > 0 ? (
                        <>
                            {searchedPosts.map((post) => {
                                return (
                                    <div
                                        key={post.node.id}
                                        style={{ margin: "0.75rem 1rem" }}
                                    >
                                        <Link to={`/${post.node.slug}/`}>
                                            {post.node.title}
                                        </Link>
                                    </div>
                                );
                            })}
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <Link to={"/search/?search=" + searchTerm}>
                                    See more results for{" "}
                                    <strong>{searchTerm}</strong>
                                </Link>
                            </div>
                        </>
                    ) : (
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <p>
                                No results found for{" "}
                                <strong>{searchTerm}</strong>
                            </p>
                        </div>
                    ))}
            </div>
        </Modal>
    );
}
